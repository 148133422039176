<template>
  <container class="collectable-detail max-w-screen-md py-16">
    <h1 class="text-3xl font-bold text-center uppercase mb-16">
      Current Hero Drop
    </h1>

    <div class="form-container">
      <form @submit="onSubmit">

        <div class="input-wrapper">
          <select v-model="heroSelectionField.value">
            <template
                v-for="collectable in listOfCollectables"
                :key="collectable && collectable.id"
            >
                <option v-if="collectable != null" :value="collectable && collectable.id">{{collectable && collectable.title}}</option>
            </template>
          </select>
          <span>{{ heroSelectionField.errors[0] }}</span>
        </div>

        <button type="submit" class="button primary">Save</button>
      </form>
    </div>
  </container>
</template>


<script>
import Container from "@/components/Container.vue";
import {reactive, computed, watchEffect} from "vue";
import {useField, useForm} from "vee-validate";
import {useToast} from "primevue/usetoast";
import { HeroService } from "@/services/apiService";
import PURCHASE_TYPE from "@/constants/PurchaseTypes.js";
import useCollectablesWithPagination from "@/hooks/useCollectablesWithPagination.js";

export default {
  name: "Hero",
  components: { Container },
  async setup() {

    const form = useForm({
        initialValues: {
            collectable_id: null,
        },
    });

    const toast = useToast();

    const paginatedCollectables = useCollectablesWithPagination(
      PURCHASE_TYPE.AUCTION
    );

    paginatedCollectables.load(12, false);

    const listOfCollectables = computed(
      () => paginatedCollectables.listOfCollectables.value
    );

    const heroSelectionField = reactive(
        useField("collectable_id", "required")
    );

    watchEffect(() => {
        if ((heroSelectionField.value === undefined) && listOfCollectables.value.find(item => item && item.featured_drop)) {
            heroSelectionField.value = listOfCollectables.value.find(item => item && item.featured_drop).id;
        }
    })

    const setHeroDrop = async (collectableId) => {
        const req = HeroService.update({collectable_id: collectableId})
        req.then(() => {
            toast.add({
                severity: 'success',
                summary: 'Confirmed',
                detail: `Hero drop successfully set`,
                life: 3000
            })
        }).catch(() => {
            toast.add({
                severity: 'error',
                summary: 'Failed',
                detail: `Hero drop was not set`,
                life: 3000
            });
        });
    }

    const onSubmit = form.handleSubmit((values) => {
        if(!values.collectable_id) {
            toast.add({
                severity: 'error',
                summary: 'Failed',
                detail: `Please use a valid collectable ID`,
                life: 3000
            });
        }else{
            setHeroDrop(values.collectable_id)
        }
    })

    return {
      listOfCollectables,
      heroSelectionField,
      onSubmit,
    };
  },
};
</script>

<style scoped>
</style>
